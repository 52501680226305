import Vue from "vue";
import "vue-snotify/styles/material.css";
import Snotify, { SnotifyPosition } from "vue-snotify";

Vue.use(Snotify, {
    toast: {
        titleMaxLength: 30,
        timeout: 2000, // 2s
        showProgressBar: false,
        pauseOnHover: true,
    }
});