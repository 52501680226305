import Vue from "vue";

/*
Usage:
<v-text-field
clearable
v-disabled-icon-focus
/>

v-disabled-icon-focus
is globaly usable for making tabulator skip the clear button

TODO: https://github.com/vuetifyjs/vuetify/issues/11202 sobald das Issue implementiert wurde anpassen
*/
Vue.directive("disabledIconFocus",  {
  bind(el) {
    el.querySelectorAll('.v-input__icon button').forEach(x => x.setAttribute('tabindex', -1));
  },
});


/*
A reusable directive for Vue.js that loads an image requiring authentication and includes it as 
data in-line in your web pages.

Stolen from: https://www.npmjs.com/package/vue-auth-image
*/

const triggerEvent = (el, eventType, detail) =>
  el.dispatchEvent(new CustomEvent(eventType, { detail }));

function setSrc(el, binding, type) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    var url = binding.value;
    Vue.prototype.$http.get(
      url, {
        headers: {
          Accept: type, // "image/png",
        },
        responseType: 'arraybuffer'
      }
    )
    .then(function(response) {
      var mimeType = response.headers['content-type'].toLowerCase();
      var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
      el.src = 'data:' + mimeType + ';base64,' + imgBase64;
      triggerEvent(el, 'sourceLoaded', { successfully: true });
    }).catch((function() {
      el.src = url;
      triggerEvent(el, 'sourceLoaded', { successfully: false });
    }));
  }
}

Vue.directive('auth-image', {
  bind: function(el, binding) {
    setSrc(el, binding, "image/png");
  },
  componentUpdated: function(el, binding) {
    setSrc(el, binding, "image/png");
  }
});


Vue.directive('auth-audio-mp3', {
  bind: function(el, binding) {
    setSrc(el, binding, "audio/mp3");
  },
  componentUpdated: function(el, binding) {
    setSrc(el, binding, "audio/mp3");
  }
});

Vue.directive('auth-audio-wav', {
  bind: function(el, binding) {
    setSrc(el, binding, "audio/wav");
  },
  componentUpdated: function(el, binding) {
    setSrc(el, binding, "audio/wav");
  }
});