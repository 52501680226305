import Vue from "vue";
import Vuetify from "vuetify/lib";
import LangFlag from "vue-lang-code-flags";
import CountryFlag from "vue-country-flag";
import VueQRCodeComponent from "vue-qrcode-component";
import ExcelDownload from "@/components/basics/icons/ExcelDownload.vue";
import PdfDownload from "@/components/basics/icons/PdfDownload.vue";
import SimOutlineESim from "@/components/basics/icons/SimOutlineESim.vue";
import en from "vuetify/es5/locale/en";
import de from "vuetify/es5/locale/de";
import store from "@/store";
import { vuetifyThemes } from "@/config";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);
Vue.component("lang-flag", LangFlag);
Vue.component("country-flag", CountryFlag);
Vue.component("qr-code", VueQRCodeComponent);

export default new Vuetify({
  lang: {
    locales: { en, de },
    current: store.state.locale,
  },
  icons: {
    iconfont: "mdi",
    values: {
      PdfDownload: {
        component: PdfDownload,
      },
      ExcelDownload: {
        component: ExcelDownload,
      },
      SimOutlineESim: {
        component: SimOutlineESim,
      },
    },
  },
  theme: {
    dark:
      localStorage.getItem("darkMode", "false") == "auto"
        ? window.matchMedia("(prefers-color-scheme:dark)").matches
        : localStorage.getItem("darkMode", "false") == "true",
    themes: vuetifyThemes,
  },
});
