/**
 * SAK routes
 */

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginCidaas"),
    meta: {
      title: "login",
      active: true,
      keepAlive: false,
      authRequired: false,
      showBackground: true,
    },
  },
  {
    path: "/login-callback",
    name: "login-callback",
    component: () => import("@/views/LoginCallback"),
    meta: {
      title: "login",
      active: true,
      keepAlive: false,
      authRequired: false,
      showBackground: false,
    },
  },
  {
    path: "/logout-callback",
    name: "logout-callback",
    component: () => import("@/views/LogoutCallback"),
    meta: {
      title: "logout",
      active: true,
      keepAlive: false,
      authRequired: false,
      showBackground: true,
    },
  },
  {
    path: "/staff/login",
    name: "staff-login",
    component: () => import("@/views/Login"),
    meta: {
      title: "login",
      active: true,
      keepAlive: false,
      authRequired: false,
      showBackground: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    redirect: { name: "main-settings", replace: true },
    component: () => import("@/views/Settings"),
    meta: {
      title: "settings",
      icon: "mdi-application-cog-outline",
      scope: "main",
      active: true,
      keepAlive: false,
      authRequired: true,
    },
    children: [
      {
        path: "base",
        name: "main-settings",
        component: () => import("@/views/settings/BaseSettings"),
        meta: {
          title: "settings",
          icon: "mdi-account-cog-outline",
          scope: "main",
          active: true,
          keepAlive: false,
          authRequired: true,
        },
      },
    ],
  },
];
