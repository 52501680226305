import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import i18n from "@/plugins/i18n";
import { config } from "@/config";
import checkPermissions from "./permissions";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query != null && to.query.password_change_token) {
    // TODO: legacy password reset - remove later
    console.log("router: legacy email password token ...");
    next({
      name: "password-change-email",
      params: { token: to.query.password_change_token },
    });
  } else if (to.query != null && to.query.email_change_token) {
    // TODO: legacy password reset - remove later
    console.log("router: legacy change token ...");
    next({
      name: "email-change-email",
      params: { token: to.query.email_change_token },
    });
  } else if (
    !store.getters.isReady &&
    to.meta != null &&
    to.meta.authRequired
  ) {
    // redirect to login if not ready
    console.log("router: not ready (" + to.name + "), redirect to login ...");
    var params = {
      name: "login",
      replace: true,
      params: { next: null },
    };
    if (from.query != null && from.query.next == null)
      params.query = {
        next: window.location.pathname + window.location.search,
      };
    next(params);
  } else if (!checkPermissions(to)) {
    // permission denied
    console.log("router: permission denied ...");
    store.commit(
      "setSystemError",
      i18n.t("You have no permissions to open {url}.", {
        url: to.fullPath,
      })
    );
    next({ name: "home" });
  } else {
    next();
  }
});

router.afterEach((to, from, failure) => {
  if (to.params != null && to.params.reloadSite) {
    location.replace(to.path);
  }
});

/*
 * avoid "loading chunk" errors
 * https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
 */
router.onError((error, to, from) => {
  if (/(loading( css)? chunk [a-z0-9\-]+ failed.)/i.test(error.message)) {
    console.log(
      ">>> got chunk load error: ",
      error.message,
      " from: ",
      from,
      " to:",
      to
    );
    // set App component chunkLoadError
    if (window.vm != null) {
      console.log(">>> set chunkLoadError to force reload");
      window.vm.$root.$children[0].chunkLoadError = true;
    } else {
      console.log(">>> window.vm not found: force reload");
      location.replace(to.path);
    }
  }
});

export default router;
export { routes };
