/**
 * store for gfo webcomponent
 */
import {setCookie} from "@/plugins/cookie.js"

export default {
  namespaced: true,
  state: {
    order_for: {
      order_by_number: 0,
      order_for_number: "",
      order_for_name: "nicht festgelegt", 
      order_for_has_active_products: true,
    },

    order_for_choices: []

  },
  mutations: {
    order_for(state, value){
      if (typeof(value) != "object" 
      || !value.hasOwnProperty("order_by_number")
      || !value.hasOwnProperty("order_for_number")
      || !value.hasOwnProperty("order_for_name")
      ) {
        console.log(value, "kann nicht gespeichert werden als order_for")
      } else {
        state.order_for = value
        setCookie("gfo_order_for", JSON.stringify(value))
      }
    },
    order_for_choices(state, value){
      state.order_for_choices = value
    } 
  },

     
};
