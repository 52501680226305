import store from "@/store";

const setGfoDynamicRoutes = async function (/* config object */ config) {
    let partnerType = store.state.session.partnerType;
    let gfoApiToken = "";
    let gfoApiUrl = config.gfoApiUrl;
    if ('END' == partnerType) {
        // Endkunde oder Partner der für Endkunde bestellt
        gfoApiToken = config.gfoApiTokenEnd;
    } else if ('WHO' == partnerType) {
        gfoApiToken = config.gfoApiTokenWho;
    } else {
        gfoApiToken = config.gfoApiTokenPar;
    }
    if (gfoApiToken != "") {
        try {
            let timeoutController = new AbortController();
            let timeoutId = window.setTimeout(function(){
                timeoutController.abort();
            }, 15000); // Abbruch nach 15 Sekunden
            let response = await fetch(`${gfoApiUrl}/netze`, {
                method: "GET",
                headers: new Headers({
                    "Accept": "application/json",
                    "X-API-TOKEN": gfoApiToken,
                }),
                signal: timeoutController.signal,
            });
            window.clearTimeout(timeoutId);
            if (200 == response.status) {
                let data = await response.json();
                let newRoles = [];
                data.forEach(function (item) {
                    let newRole = `API-ORD-${item.type.toString().toUpperCase()}`;
                    if (-1 < ["gf", "dsl"].indexOf(item.type)) {
                        newRole = "API-ORD-ACCESS";
                    }
                    if (-1 == newRoles.indexOf(newRole)) {
                        newRoles.push(newRole);
                    }
                });
                if (0 < newRoles.length) {
                    store.state.session.apiRoles.push(...newRoles);
                }
            }
        } catch (err) {
            console.log("Aufruf GFO:" + err);
        }
    }

    // Triggere URL-Change in ResellerGfoNetzLoading
    const event = new Event("gfoDynamicRoutesLoaded");
    document.dispatchEvent(event);
}

export {setGfoDynamicRoutes};
export default setGfoDynamicRoutes;