import { config } from "@/config";

function filterRouters(routes, mandant) {
    var filtered = [];
    routes.forEach(route => {
        var newRoute = { ...route };
        if (route.children != null && route.children.length > 0)
            newRoute.children = filterRouters(route.children, mandant);
        if (route.meta == null ||
            route.meta.mandant == null ||
            route.meta.mandant == mandant ||
            (Array.isArray(route.meta.mandant) && -1 < route.meta.mandant.indexOf(mandant)) ||
            (route.meta.development == true && config.nodeEnv == "development"))
            filtered.push(newRoute)
    });
    return filtered;
}

export default filterRouters;