/*
HttpError class.

var error = HttpError(error); 

With "error" is an axios error instance.

Provides or extend the following attributes or methodes:

* status: HTTP status (can be null)
* data: response data object (can be null)
* message: extended error message
* title: error title or category
* success(): should be false in any cases
* redirected(): should be fals ein any cases
* invalid(): is 4xx error
* failed(): is 5xx, network or timeout error
* unauthorized(): is 401 error
* forbidden(): is 403 error
* notFound(): is 404 error

*/

import ValidationErrorData from "./ValidationErrorData.ts";


function getError(data) {
  if (typeof data == "string" && typeof data == "number")
    return new ValidationErrorData({
      detail: [String(data)]
    });
  else if (typeof data == "array") {
    return new ValidationErrorData({
      detail: data
    });
  } else if (typeof data == "object")
    return new ValidationErrorData(data);
  else
    return new ValidationErrorData({});
}

class HttpError {
  constructor(error) {
    this.error = error;
    this.response = error.response;
    this.request = error.request;
    this.status = null;
    this.data = {};
    this.message = error.message;
    this.type = null;
    this.title = null;
    this.parse();
  }
  parse() {
    if (this.error.response) {
      var that = this;

      // Request made and server responded
      this.status = this.error.response.status;

      if (this.status >= 400 && this.status < 500) {
        this.type = 'user';
        this.title = "User Error";
        this.data = getError(this.error.response.data);

        // try to set a propper message
        if (typeof this.data == "object") {
          Object.getOwnPropertyNames(this.data).forEach(function (name) {
            if (typeof that.data[name] == "array" && that.data[name].length > 0)
              that.message = that.data[name][0];
            else that.message = String(that.data[name]);
          });
        } else if (typeof this.data == "array" && this.data.length > 0) {
          this.message = this.data[0];
        }

      } else if (this.status >= 500) {
        this.type = 'server';
        this.title = "Server Error";
        switch (this.status) {
          case 500: // Internal Server Error
            this.data = getError(this.error.response.data);
            this.message =
              this.data.detail || this.data.non_field_errors || this.error.response.data;
            if (typeof this.message == "array")
              this.message = this.message[0];
            break;
          case 502: // Bad Gateway
          case 503: // Service Unavailable
            this.message = "backend not available";
            break;
          case 504: // Gateway Timeout
            this.message = "backend timeout";
            break;
          default:
            this.message = "unexpected backend error";
        }
        this.message = this.message + " [" + this.error.response.status + "]";
      }

    } else if (this.error.request) {
      // The request was made but no response was received
      console.log(">>> axios: request error ", this.error);
      if (this.error.code == "ECONNABORTED") {
        this.type = 'timeout';
        this.title = "Server Timeout";
      } else {
        this.type = 'network';
        this.title = "Network Error";
      }
      if (this.error.stack)
        this.message = this.error.stack;
      if (this.error.code)
        this.message += " [" + this.error.code + "]";
      
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(">>> axios: unknown error ", this.error);
      this.type = 'unknown';
      this.title = "Unknown Error";
      this.message = "unknown error";
      if (this.error.stack)
        this.message += ": " + this.error.stack;
    }

    // make sure that data is instance of ValidationErrorData
    if (!(this.data instanceof ValidationErrorData))
      this.data = getError(this.message);
  }
  success() {
    return this.status != null && this.status >= 200 && this.status < 300;
  }
  redirected() {
    return this.status != null && this.status >= 300 && this.status < 400;
  }
  invalid() {
    // ignore HTTP 401, 403 and 404 errors which will be handled in axios
    if ([401, 403, 404].includes(this.status)) {
      return false;
    } else {
      return this.status != null && this.status >= 400 && this.status < 500;
    }
  }
  failed() {
    return this.status == null || this.status >= 500;
  }
  unauthorized() {
    return this.status != null && this.status == 401;
  }
  forbidden() {
    return this.status != null && this.status == 403;
  }
  notFound() {
    return this.status != null && this.status == 404;
  }
}

export default HttpError;
