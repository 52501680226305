export default {
  title: "RZO AG Login Portal",
  copyright: "RZO AG",
  favicon: process.env.BASE_URL + "rzo-favicon.ico",
  logo: require("@/assets/rzo-logo.png"),
  msgChangePermissionGroup: "loginrzoagch_admin_access",
  frontendUrl: "https://login.rzoag.ch",
  backendUrl: "https://backend.login.rzoag.ch/api/",
  backgroundImage: require("@/assets/rzo-background.jpg"),
  billingTypes: [
    {
      id: 0,
      title_de: "ESR",
      title_en: "Payment slip by post",
      visible: true,
      disabled: false,
    },
    {
      id: 1,
      title_de: "Kreditkarte",
      title_en: "Credit card",
      visible: true,
      disabled: false,
    },
    {
      id: 3,
      title_de: "Rechnung ohne Einzahlungsschein",
      title_en: "Post without payment slip",
      visible: true,
      disabled: false,
    },
    {
      id: 4,
      title_de: "Mail",
      title_en: "Email",
      visible: true,
      disabled: false,
    },
    {
      id: 5,
      title_de: "Kreditkarte, Bestätigung per Mail",
      title_en: "Credit card, confirmation by email",
      visible: true,
      disabled: false,
    },
    {
      id: 6,
      title_de: "ESR und Mail",
      title_en: "Payment slip and email",
      visible: true,
      disabled: false,
    },
    {
      id: 9,
      title_de: "LSV+, Bestätigung per Brief",
      title_en: "LSV+, confirmation by post",
      visible: true,
      disabled: false,
    },
    {
      id: 10,
      title_de: "LSV+, Bestätigung per Mail",
      title_en: "LSV+, confirmation by email",
      visible: true,
      disabled: false,
    },
    {
      id: 11,
      title_de: "eBill, Beleg per Mail",
      title_en: "eBill, receipt by email",
      note_de: "im E-Banking unter E-Bill Rechnungssteller RZO AG hinzufügen",
      note_en: "add biller RZO AG in e-banking under e-bill",
      visible: true,
      disabled: false,
    },
    {
      id: 12,
      title_de: "eBill, kein Beleg per Mail",
      title_en: "eBill, without receipt my email",
      note_de: "im E-Banking unter E-Bill Rechnungssteller RZO AG hinzufügen",
      note_en: "add biller RZO AG in e-banking under e-bill",
      visible: true,
      disabled: false,
    },
    {
      id: 13,
      title_de: "DebitDirect per Mail",
      title_en: "Debit direct by email",
      visible: true,
      disabled: false,
    },
    {
      id: 19,
      title_de: "DTAUS, Bestätigung per Brief",
      title_en: "DTAUS, confirmation by post",
      visible: false,
      disabled: false,
    },
    {
      id: 20,
      title_de: "DTAUS, Bestätigung per Mail",
      title_en: "DTAUS, confirmation by email",
      visible: false,
      disabled: false,
    },
  ],
};
