/*
ValidationErrorData class.

var data = ValidationErrorData(response.data);

With "response.data" is an dictionary from JSON response.

Normalize data to:

{
  'key1': ['value1', 'value2', ...],
  'key2': ['value1', 'value2', ...],
  ...
}

and provides methodes:

join(s=', ') which convert data to:

{
  'key1': 'value1, value2, ...',
  'key2': 'value1, value2, ...',
  ...
}

mapDefault(to) which add "detail" key to "to".

*/

export default class ValidationErrorData {
  constructor(data) {
    this.normalize(data);
  }
  normalize(data, prefix = "") {
    var that = this;
    for (const [key, value] of Object.entries(data)) {
      if (Object.prototype.toString.call(value) === "[object Object]") {
        this.normalize(value, prefix + key + ".");
      } else if (Object.prototype.toString.call(value) === "[object Array]") {
        if (value.length > 0 && Object.prototype.toString.call(value[0]) === "[object Object]") {
          value.forEach(function (v, i) {
            that.normalize(v, prefix + key + "." + i + ".");
          });
        } else {
          this[prefix + key] = value;
        }
      } else if (value == null) {
        this[prefix + key] = null;
      } else {
        this[prefix + key] = [String(value)];
      }
    }
  }
  join(s = ", ") {
    var data = {};
    for (const [key, array] of Object.entries(data)) {
      data[key] = array.join(s);
    }
    return new ValidationErrorData(data);
  }
  get(key, data = null) {
    data = data || this;
    var keys = key.split(".");
    var k = keys.shift();
    if (k in data) {
      if (keys.length > 0) {
        return this.get(keys.join("."), data[k]);
      } else {
        return data[k];
      }
    } else {
      return [];
    }
  }
  mapDefault(to, key = null) {
    if (!this.hasOwnProperty(to)) {
      this[to] = [];
    }

    if (key != null && this.hasOwnProperty(key))
      this[to] = this[to].concat(this[key]);

    else if (this.hasOwnProperty("response"))
      this[to] = this[to].concat(this["response"]);

    else if (this.hasOwnProperty("default"))
      this[to] = this[to].concat(this["detail"]);

    else if (this.hasOwnProperty("non_field_errors"))
      this[to] = this[to].concat(this["non_field_errors"]);

    else if (this.hasOwnProperty("detail"))
      this[to] = this[to].concat(this["detail"]);
  }
}
