/**
 * internet order store
 */

const orderContactDefault = {
  company: "",
  salutation: "n",
  first_name: "",
  last_name: "",
  phone: "",
  mobile: "",
  fax: "",
  email: "",
  street: "",
  house_number: "",
  zip: "",
  city: "",
  additional: "",
  country: "CH",
  language: "de",
  comment: "",
  note: "",
};

const predecessorInfoDefault = {
  predecessor_company: "",
  predecessor_first_name: "",
  predecessor_last_name: "",
};

const addressDefault = {
  first_name: "",
  last_name: "",
  zip: "",
  city: "",
  street: "",
  street_number: "",
  building: ""
};

export default {
  namespaced: true,
  state: {
    ispChange: false,
    bbcsProperties: {},
    bbcsRadiusSuffixIdx: null,
    bbcsRadiusUsername: "",
    bbcsRadiusPassword: "",
    bbcsResults: [],
    bbcsResultIdx: null,
    bbcsProfileIdx: null,
    checkResults: [],
    checkIdx: null,
    resultIdx: null,
    serviceIdx: null,
    profileIdx: null,
    otoIdx: null,
    timeSlotIdx: null,
    address: { ...addressDefault },
    number: "",
    oto: "",
    plug: "",
    bindingId: "",
    llId: "",
    wishDate: "",
    streaming: false,
    sessionType: "DHCP",
    pool: "pool1",
    bbType: null,
    newLoop: false,
    lineState: "all",
    isBbcs: false,
    options: [],
    productDescr: "",
    orderEmail: "",
    orderNote: "",
    orderContact: { ...orderContactDefault },
    predecessorInfo: { ...predecessorInfoDefault },
    slaEmail: "",
    slaMobile: "",
    ripeNetname: "",
    routerType: null,
    xgsSerialNumber: null,
  },
  mutations: {
    setIspChange(state, value) {
      state.ispChange = value == null ? false : value;
    },
    setBbcsProperties(state, value) {
      state.bbcsProperties = { ...value };
      var i = 0;
      (state.bbcsProperties.suffixes != null ? state.bbcsProperties.suffixes : []).forEach(function (suffix) {
        suffix.id = i;
        i += 1;
      });
    },
    setBbcsRadiusSuffixIdx(state, value) {
      state.bbcsRadiusSuffixIdx = value;
    },
    setBbcsRadiusUsername(state, value) {
      state.bbcsRadiusUsername = value;
    },
    setBbcsRadiusPassword(state, value) {
      state.bbcsRadiusPassword = value;
    },
    setBbcsResults(state, value) {
      state.bbcsResults = [...value];
    },
    setBbcsResultIdx(state, value) {
      state.bbcsResultIdx = value;
    },
    setBbcsProfileIdx(state, value) {
      state.bbcsProfileIdx = value;
    },
    setTimeSlotIdx(state, value) {
      state.timeSlotIdx = value;
    },
    setCheckResults(state, value) {
      state.checkResults = [...value];
    },
    setCheckIdx(state, value) {
      state.checkIdx = value;
    },
    setResultIdx(state, value) {
      state.resultIdx = value;
    },
    setServiceIdx(state, value) {
      state.serviceIdx = value;
    },
    setProfileIdx(state, value) {
      state.profileIdx = value;
    },
    setOtoIdx(state, value) {
      state.otoIdx = value;
    },
    setAddress(state, value) {
      state.address = { ...value };
    },
    setNumber(state, value) {
      state.number = value;
    },
    setOto(state, value) {
      state.oto = value;
    },
    setPlug(state, value) {
      state.plug = value;
    },
    setBindingId(state, value) {
      state.bindingId = value;
    },
    setLlId(state, value) {
      state.llId = value;
    },
    setWishDate(state, value) {
      state.wishDate = value;
    },
    setSessionType(state, value) {
      state.sessionType = value;
    },
    setStreaming(state, value) {
      state.streaming = value;
    },
    setPool(state, value) {
      state.pool = value;
    },
    setBbType(state, value) {
      state.bbType = value;
    },
    setNewLoop(state, value) {
      state.newLoop = value;
    },
    setLineState(state, value) {
      state.lineState = value;
    },
    setIsBbcs(state, value) {
      state.isBbcs = value;
    },
    setOptions(state, options) {
      state.options = [...options];
    },
    updateOption(state, opt) {
      var options = [...state.options];
      var found = false;
      options.forEach(function (o, i) {
        if (o.id == opt.id) {
          options[i] = { ...opt };
          found = true;
        }
      });
      if (!found)
        options.push({ ...opt });
      state.options = options;
    },
    setProductDescr(state, value) {
      state.productDescr = value;
    },
    setOrderEmail(state, value) {
      state.orderEmail = value;
    },
    setOrderNote(state, value) {
      state.orderNote = value;
    },
    setOrderContact(state, value) {
      state.orderContact = { ...value };
    },
    setPredecessorInfo(state, value) {
      state.predecessorInfo = { ...value };
    },
    setSlaEmail(state, value) {
      state.slaEmail = value;
    },
    setSlaMobile(state, value) {
      state.slaMobile = value;
    },
    setRipeNetname(state, value) {
      state.ripeNetname = value;
    },
    setRouterType(state, value) {
      state.routerType = value;
    },
    setXgsSerialNumber(state, value) {
      state.xgsSerialNumber = value;
    },
  },
  getters: {
    bbcsRadiusSuffixes: state => {
      return state.bbcsProperties.suffixes != null
        ? state.bbcsProperties.suffixes
        : [];
    },
    bbcsRadiusSuffix: (state, getters) => {
      if (state.bbcsRadiusSuffixIdx != null && getters.bbcsRadiusSuffixes.length > state.bbcsRadiusSuffixIdx)
        return getters.bbcsRadiusSuffixes[state.bbcsRadiusSuffixIdx];
      return null;
    },
    bbcsUsernameWithSuffix: (state, getters) => {
      return (state.bbcsRadiusUsername || "") + (
        getters.bbcsRadiusSuffix != null ? (getters.bbcsRadiusSuffix.suffix || "") : "")
    },
    bbcsResult: state => {
      if (state.bbcsResultIdx != null && state.bbcsResults.length > state.bbcsResultIdx)
        return state.bbcsResults[state.bbcsResultIdx];
      return null;
    },
    bbcsResultStateOk: (state, getters) => {
      return (
        getters.result == null ||
        getters.result.provider.indexOf("bbcs") < 0 || (
          getters.bbcsResult != null && (
            getters.bbcsResult.result_state == 'ok' || (
              getters.bbcsResult.result_state == 'nok' &&
              getters.bbcsResult.allow_create != null &&
              getters.bbcsResult.allow_create == false &&
              getters.bbcsResult.allow_create_on_nok != null &&
              getters.bbcsResult.allow_create_on_nok == true
            )
          )
        )
      );
    },
    bbcsResultStateOkInfo: (state, getters) => {
      return (
        getters.result == null ||
        getters.result.provider.indexOf("bbcs") < 0 || (
          getters.bbcsResult != null &&
          getters.bbcsResult.result_state == 'nok' &&
          getters.bbcsResult.allow_create != null &&
          getters.bbcsResult.allow_create == false &&
          getters.bbcsResult.allow_create_on_nok != null &&
          getters.bbcsResult.allow_create_on_nok == true
        )
      );
    },
    result: state => {
      if (state.resultIdx != null && state.checkResults.length > state.resultIdx)
        return state.checkResults[state.resultIdx];
      return null;
    },
    services: (state, getters) => {
      if (getters.result != null) return getters.result.services;
      return [];
    },
    service: (state, getters) => {
      if (
        state.serviceIdx != null &&
        getters.result != null &&
        getters.result.services.length > state.serviceIdx
      )
        return getters.result.services[state.serviceIdx];
      return null;
    },
    profiles: (state, getters) => {
      if (getters.service != null) return getters.service.profiles;
      return [];
    },
    profile: (state, getters) => {
      if (
        state.profileIdx != null &&
        getters.service != null &&
        getters.service.profiles.length > state.profileIdx
      )
        return getters.service.profiles[state.profileIdx];
      return null;
    },
    otos: (state, getters) => {
      if (getters.service != null) return getters.service.otos;
      return [];
    },
    oto: (state, getters) => {
      if (
        state.otoIdx != null &&
        getters.service != null &&
        getters.service.otos.length > state.otoIdx
      )
        return getters.service.otos[state.otoIdx];
      return null;
    },
    timeSlots: (state, getters) => {
      if (getters.service != null) return getters.service.timeSlots;
      return [];
    },
    timeSlot: (state, getters) => {
      if (
        state.timeSlotIdx != null &&
        getters.timeSlots.length > state.timeSlotIdx
      )
        return getters.timeSlots[state.timeSlotIdx];
      return null;
    },
    checkBy: state => {
      switch (state.checkIdx) {
        case 0:
          return "ADDRESS";
        case 1:
          return "NUMBER";
        case 2:
          return "OTO";
        case 3:
          return "BINDING";
        case 4:
          return "LLID";
      }
    },
    dnType: (state) => {
      return state.bbType === "FTTH" || state.bbType === "BX" || state.bbType === "XGS" ? "FTTH" : "NAKED";
    },
    installAddress: (state, getters) => {
      var addr = null;
      if (
        getters.service != null &&
        getters.service.address != null &&
        getters.service.address.zip != null
      )
        addr = {
          ...getters.service.address,
          building: state.address.building || ""
        };
      else addr = { ...state.address };
      /* map street_number to house_number
       * TODO: cleanup street_number nightmare in backend :-(
      */
      addr.house_number = addr.street_number;
      delete addr.street_number;
      return addr;
    },
    serviceAddress: (state, getters) => {
      if (
        state.serviceIdx != null &&
        getters.result != null &&
        getters.result.services.length > state.serviceIdx &&
        getters.result.services[state.serviceIdx].address != null
      )
        return getters.result.services[state.serviceIdx].address;
      return null;
    },
    isBbcsResult: (state, getters) => {
      return (
        getters.result != null && getters.result.provider.search("bbcs") === 0
      );
    },
    contactNeeded: (state, getters) => {
      return (
        (getters.service != null && getters.service.contact_needed === true) || !state.isBbcs
      );
    },
    allowSessionTypePpp: (state, getters) => {
      // disable PPPoE for XGS orders above 1Gbps
      return (
        getters.profile == null || getters.result.provider != "bbcs_xgspon" || getters.profile.bw_down <= 1000000
      );
    },
  },
  actions: {
    setBbcsProperties({ commit }, { value, suffix }) {
      commit("setBbcsProperties", value);
      var i = 0;
      (value.suffixes != null ? value.suffixes : []).forEach(function (suf) {
        if (suffix != null && suffix == suf.suffix)
          commit("setBbcsRadiusSuffixIdx", i);
        else if (suffix == null && suf.default) commit("setBbcsRadiusSuffixIdx", i);
        i += 1;
      });
    },
    resetCheckResults({ commit, dispatch }, results) {
      commit("setCheckResults", results);
      commit("setOptions", []);
      dispatch("setResultIdx", results.length > 0 ? 0 : null);
    },
    resetCheckResultsUnselected({ commit, dispatch }, results) {
      commit("setCheckResults", results);
      commit("setOptions", []);
      dispatch("setResultIdx", results.length === 1 ? 0 : null);
    },
    setResultIdx({ commit, dispatch, getters }, value) {
      commit("setResultIdx", value);
      dispatch("setServiceIdx",
        getters.result != null && getters.result.services.length > 0
          ? 0
          : null
      );
      commit("setIsBbcs", getters.isBbcsResult);
    },
    setServiceIdx({ commit, dispatch, getters }, value) {
      commit("setServiceIdx", value);
      commit("setProfileIdx", null);
      commit("setTimeSlotIdx", null);
      dispatch("setOtoIdx", null);
      dispatch("setAddressByServiceIdx", value);
      if (getters.result && getters.isBbcsResult)
        commit("setBbType", getters.service != null ? getters.service.name : null);
      else if (getters.service != null) commit("setBbType", "BX");
      else commit("setBbType", null);
      commit("setProfileIdx",
        getters.service != null && getters.service.profiles.length > 0
          ? getters.service.profiles.length - 1
          : null
      );
    },
    setOtoIdx({ commit, getters }, value) {
      commit("setOtoIdx", value);
      /* we assume that oto is an object with ' oto_id' and 'plug_nr' 
         and set store oto and plug autom.  */
      let oto = getters.oto;
      if (oto != null && oto.oto_id != null) {
        commit("setOto", oto.oto_id);
        if (oto.plug_nr != null)
          commit("setPlug", oto.plug_nr);
      }
    },
    setTimeSlotIdx({ commit, getters }, value) {
      commit("setTimeSlotIdx", value);
      if (getters.timeSlot != null) commit("setWishDate", getters.timeSlot.date);
    },
    setAddressByServiceIdx({ commit, dispatch, getters }, idx) {
      let serviceAddress = getters.serviceAddress;
      if (serviceAddress != null) commit("setAddress", serviceAddress);
    },
    resetOrderContact({ commit }) {
      commit("setOrderContact", orderContactDefault);
    },
    resetPredecessorInfo({ commit }) {
      commit("setPredecessorInfo", predecessorInfoDefault);
    },
    resetOrderStore({ commit, dispatch }) {
      dispatch("resetCheckResults", []);
      dispatch("resetOrderContact");
      dispatch("resetPredecessorInfo");
      commit("setIspChange", false);
      commit("setBbcsRadiusUsername", "");
      commit("setBbcsRadiusPassword", "");
      commit("setAddress", addressDefault);
      commit("setNumber", "");
      commit("setOto", "");
      commit("setPlug", "");
      commit("setBindingId", "");
      commit("setLlId", "");
      commit("setWishDate", "");
      commit("setSessionType", "DHCP");
      commit("setStreaming", false);
      commit("setPool", "pool1");
      commit("setBbType", null);
      commit("setNewLoop", false);
      commit("setLineState", "all");
      commit("setIsBbcs", false);
      commit("setOptions", []);
      commit("setProductDescr", "");
      commit("setOrderNote", "");
      commit("setOrderEmail", "");
      commit("setSlaEmail", "");
      commit("setSlaMobile", "");
      commit("setRipeNetname", "");
      commit("setRouterType", null);
    },
  }
};
