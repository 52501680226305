import { config } from "@/config";
import filterRoutes from "../filterRoutes";
import commonRoutes from "./common";
import iwayRoutes from "./iway";
import rzoRoutes from "./rzo";
import sakRoutes from "./sak";

function mergedRoutes() {
  switch (config.mandant) {
    case "iway":
      return [...commonRoutes, ...iwayRoutes];
      break;
    case "rzo":
      return [...commonRoutes, ...rzoRoutes];
      break;
    case "sak":
      return [...commonRoutes, ...sakRoutes];
      break;
    default:
      return [...commonRoutes];
      break;
  }
}

export default filterRoutes(mergedRoutes(), config.mandant);
