import colors from "vuetify/lib/util/colors";
export default {
  light: {
    titleBar: colors.purple.darken3,
    primary: colors.purple.darken3,
    primaryText: colors.yellow.base,
    secondary: colors.yellow.base,
    secondaryText: colors.purple.darken3,
    error: colors.red.darken4,
    info: colors.blueGrey.darken2,
    success: colors.green.darken2,
    warning: colors.amber.base,
    inactive: colors.grey.lighten2,
    workerRunning: colors.lightBlue.base,
    workerWaiting: colors.cyan.base,
    workerFinished: colors.green.base,
    workerFailed: colors.deepOrange.darken4,
    workerCanceled: colors.grey.base,
    staff: colors.amber.accent4,
  },
  dark: {
    titleBar: colors.blueGrey.darken4,
    primary: colors.purple.darken3,
    primaryText: colors.yellow.base,
    secondary: colors.yellow.base,
    secondaryText: colors.purple.darken3,
    error: colors.red.lighten2,
    info: colors.blueGrey.lighten2,
    success: colors.green.lighten2,
    warning: colors.amber.base,
    inactive: colors.grey.darken3,
    workerRunning: colors.lightBlue.base,
    workerWaiting: colors.cyan.base,
    workerFinished: colors.green.base,
    workerFailed: colors.red.base,
    workerCanceled: colors.grey.base,
    staff: colors.amber.accent4,
  },
};
