/**
 * ip6addr: https://github.com/joyent/node-ip6addr - best IP functions
 * binet: https://github.com/bcoin-org/binet/ - best IP parser
 */

const binet = require('binet');
const ip6addr = require('ip6addr');

/**
 * Returns "true" or "false" if "s" is a valid IP.
 * "127" -> true
 * "127.0" -> true
 * "127.0." -> false
 * "127.0.0.0/8" -> true
 * "2001:8e0:40:2:2834:78ba:9a6c:63cd" -> true
 * 
 * @param {String} s 
 */
function isIP(s) {
    return binet.isIPString(s) > 0
}

/**
 * Normalize IP string
 * @param {String} s 
 */
function normalize(s) {
    try {
        var raw = binet.decode(s);
        return binet.toString(raw)
    } catch (err) {
        return null;
    }
}

/**
 * Convert IP string to IP Addr
 * @param {String} s 
 */
function parse(s) {
    try {
        var str = normalize(s)
        return ip6addr.parse(str);
    } catch (err) {
        return null;
    }
}

/**
 * Checks IP string is IPv4
 * @param {String} s 
 */
 function isIpv4(s) {
    try {
        var str = normalize(s)
        return ip6addr.parse(str).kind() == 'ipv4';
    } catch (err) {
        return null;
    }
 }

 /**
 * Checks IP string is IPv4
 * @param {String} s 
 */
  function isIpv6(s) {
    try {
        var str = normalize(s)
        return ip6addr.parse(str).kind() == 'ipv6';
    } catch (err) {
        return null;
    }
}

/**
 * Gets length (number of addresses) from network
 * @param {String} s 
 */
function length(s) {
    try {
        var net = ip6addr.createCIDR(s);
        var prefixLength = net.prefixLength();
        if (net._addr.kind() === 'ipv4') {
            return 2 ** (32 - prefixLength)
        }
        return 2 ** (128 - prefixLength);
    } catch (err) {
        return null;
    }
}

const compare = ip6addr.compare;
const createCIDR = ip6addr.createCIDR;
const createAddrRange = ip6addr.createAddrRange;
const compareCIDR = ip6addr.compareCIDR;

export {
    isIP,
    normalize,
    parse,
    isIpv4,
    isIpv6,
    length,
    compare,
    createCIDR,
    createAddrRange,
    compareCIDR
};
