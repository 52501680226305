/**
 * iWay routes
 */

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login"),
    props: (route) => ({
      next: route.query.next || route.params.next || null,
    }),
    meta: {
      title: "login",
      icon: "mdi-login-variant",
      keepAlive: false,
      authRequired: false,
      showBackground: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    redirect: { name: "main-settings", replace: true },
    component: () => import("@/views/Settings"),
    meta: {
      title: "settings",
      icon: "mdi-application-cog-outline",
      scope: "main",
      active: true,
      keepAlive: false,
      authRequired: true,
    },
    children: [
      {
        path: "base",
        name: "main-settings",
        component: () => import("@/views/settings/BaseSettings"),
        meta: {
          title: "settings",
          icon: "mdi-account-cog-outline",
          scope: "main",
          active: true,
          keepAlive: false,
          authRequired: true,
        },
      },
      {
        path: "two-factor-auth",
        name: "two-factor-auth",
        component: () => import("@/views/settings/TwoFactorAuth"),
        props: true,
        meta: {
          title: "two factor authentication",
          icon: "mdi-two-factor-authentication",
          scope: "main",
          active: true,
          keepAlive: false,
          authRequired: false,
        },
      },
      {
        path: "users",
        name: "users-manager",
        component: () => import("@/views/settings/UserManager"),
        meta: {
          title: "user manager",
          icon: "mdi-account-multiple-outline",
          scope: "main",
          active: true,
          keepAlive: false,
          authRequired: true,
          permissions: {
            permission: ["admin"],
          },
        },
      },
    ],
  },
];
