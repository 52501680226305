import Vue from "vue";

import "@/plugins/sentry";
import '@fontsource-variable/inter';
import "@fontsource/roboto";
import "@fontsource/roboto-mono";
import store from "@/store";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import client from "@/plugins/axios";
import i18n from "@/plugins/i18n";
import "@/plugins/meta";
import "@/plugins/validate";
import "@/plugins/snotify";
import "@/plugins/moments";
import "@/plugins/directives";

import { ConfigPlugin, config } from "@/config";
import App from "@/App.vue";
import "leaflet/dist/leaflet.css";

import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

/* config */

Vue.use(ConfigPlugin);
Vue.config.productionTip = false;

/* webcomponents have to be defined as ignoredElement so that the 
   compiler does not complain.
*/
Vue.config.ignoredElements = ["gfo-form"];

/* initialize store before Vue, but
   ignore login for SAK/cidaas redirects
*/
if (
  config.mandant == "sak" &&
  (window.location.pathname == "/login-callback" ||
    window.location.pathname.indexOf("/staff") == 0)
) {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    el: "#app",
    render: (h) => h(App),
    created() {
      // required for global Vue instance access
      window.vm = this;
    },
  });
} else {
  client
    .get("profile", { disableDefaultErrorHandler: true })
    .then((response) => {
      store.commit("setSession", response.data);
    })
    .catch((error) => {
      store.commit("resetSession");
      router
        .push({
          name: "login",
          replace: true,
          query:
            window.location.pathname != "/" ? window.location.pathname : null,
        })
        .catch((error) => {
          if (error != null) console.log(error);
        });
    })
    .finally(() => {
      new Vue({
        router,
        store,
        vuetify,
        i18n,
        el: "#app",
        render: (h) => h(App),
        created() {
          // required for global Vue instance access
          window.vm = this;
        },
      });
    });
}
