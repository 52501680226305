import Vue from "vue";
import store from "@/store";
import router from "@/router";
import checkPermissions from "@/router/permissions";
import axios from "axios";
import { config } from "@/config";
import i18n from "@/plugins/i18n";
import HttpError from "@/utils/errors/HttpError";

var qs = require("qs");

const client = axios.create({
  baseURL: config.backendUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Version": config.version,
    "X-Mandant": config.mandant,
  },
  timeout: config.backendTimeout || 30000,
  // disable autom. redirects
  maxRedirects: 0,
  // force 'a=b&a=c'
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

// request
client.interceptors.request.use(
  function (config) {
    config.headers["Accept-Language"] = store.state.locale;
    if (store.state.token) {
      config.headers["Authorization"] = "Bearer " + store.state.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// response
client.interceptors.response.use(
  function (response) {
    // remember backend version in store for Sentry info
    var version = response.headers["x-version"] || null;
    if (store.state.backendVersion == null && version != null)
      store.commit("setBackendVersion", version);
    else if (
      store.state.backendVersion != null &&
      version != null &&
      store.state.backendVersion !== version
    )
      store.commit("setBackendVersion", version);

    return response;
  },
  function (error) {
    var err = new HttpError(error);
    var vm = window.vm;

    if (
      error.config.hasOwnProperty("disableDefaultErrorHandler") &&
      error.config.disableDefaultErrorHandler
    ) {
      // disableDefaultErrorHandler === true
      console.log("axios: default error handler disabled by request ...");
    } else if (
      vm != null &&
      vm.$route.meta != null &&
      vm.$route.meta.disableDefaultErrorHandler
    ) {
      // route.meta.ignoreErrorHandling === true
      console.log("axios: default error handler disabled by route ...");
    } else if (
      err.unauthorized() &&
      (vm == null || (vm.$route.meta != null && vm.$route.meta.authRequired))
    ) {
      // got 401: show error and going to re-login
      console.log("axios: got 401, reset session and redirect to login ...");
      if (store.state.token != null)
        store.commit("setSystemError", {
          small: true,
          title: i18n.t("session expired"),
          msg: i18n.t("Your session seems to be expired, please login again!"),
          icon: "mdi-lock-reset",
        });
      store.commit("resetSession");
      router
        .push({
          name: "login",
          replace: true,
          query: { next: window.location.pathname },
        })
        .catch((err) => {});
    } else if (
      err.forbidden() &&
      (vm == null || (vm.$route != null && checkPermissions(vm.$route)))
    ) {
      // got 403: show error (raised if user gets an unexpected 403)
      console.log("axios: got 403, show error ...");
      store.commit("setSystemError", {
        title: i18n.t("permission denied"),
        msg: i18n.t(
          "Your backend API request has been denied, please contact your administrator!"
        ),
        icon: "mdi-lock-alert",
      });
    } else if (err.notFound()) {
      // got 404: show error
      console.log("axios: got 404, show error ...");
      store.commit("setSystemError", {
        title: i18n.t("not found"),
        msg: i18n.t(
          "Your backend API request URL could'nt be found, please contact your administrator!"
        ),
        icon: "mdi-link-off",
      });
    } else if (err.failed()) {
      // got 5xx: show error

      console.log("axios: got 5xx, show error ...", err);
      if (err.status == 429)
        store.commit("setSystemError", {
          title: i18n.t("too many requests"),
          msg: err.message,
          icon: "mdi-cancel",
        });
      else {
        var icon = "mdi-alert-circle";
        if (err.type == "timeout") icon = "mdi-timer-sand-empty";
        else if (err.type == "network") icon = "mdi-lan-disconnect";
        else if (err.type == "server") icon = "mdi-server-network-off";
        store.commit("setSystemError", {
          title: err.title,
          msg: err.message,
          icon: icon,
        });
      }
    }

    return Promise.reject(err);
  }
);

// bind our HTTP client to Vue
Vue.prototype.$http = client;

export default client;
