<template>
  <div class="mb-3 text-body-2 text-center">
    <v-row align="center" justify="center" class="primary lighten-1">
      <v-col class="py-1" cols="12">
        <span class="primaryText--text text--darken-1"
          >Version {{ $config.version }}</span
        >
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="primary">
      <v-col class="py-1" cols="12">
        <span class="primaryText--text text--darken-1">
          {{ $config.copyright }} &copy; {{ $config.copyrightStart }}
          <template v-if="new Date().getFullYear() != $config.copyrightStart">
            - {{ new Date().getFullYear() }}
          </template>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LayoutFooter",
};
</script>
