import colors from "vuetify/lib/util/colors";

const iWayLightTheme = {
  background: '#FFFFFF',
  titleBar: '#0099FF',
  primary: '#0099FF',
  primaryText: colors.shades.white,
  secondary: '#242E61',
  secondaryText: colors.shades.white,
  error: colors.red.darken4,
  info: colors.blueGrey.darken2,
  success: colors.green.darken2,
  warning: colors.amber.base,
  inactive: colors.grey.lighten2,
  workerRunning: colors.lightBlue.base,
  workerWaiting: colors.cyan.base,
  workerFinished: colors.green.base,
  workerFailed: colors.deepOrange.darken4,
  workerCanceled: colors.grey.base,
  staff: colors.amber.accent4,
}

const iWayDarkTheme = {
  titleBar: colors.blueGrey.darken4,
  primary: colors.blue.darken3,
  primaryText: colors.shades.white,
  secondary: colors.teal.base,
  secondaryText: colors.shades.white,
  error: colors.red.lighten2,
  info: colors.blueGrey.lighten2,
  success: colors.green.lighten2,
  warning: colors.amber.base,
  inactive: colors.grey.darken3,
  workerRunning: colors.lightBlue.base,
  workerWaiting: colors.cyan.base,
  workerFinished: colors.green.base,
  workerFailed: colors.red.base,
  workerCanceled: colors.grey.base,
  staff: colors.amber.accent4,
}

export default {
  light: { ...iWayLightTheme },
  dark: { ...iWayDarkTheme },
};
