import axios from 'axios';
import { config } from "@/config";

export default {
  data() {
    return {
      checkedVersion: config.version,
      versionChanged: false,
    };
  },
  methods: {
    initVersionCheck() {
      if (config.version && config.version != "0.0.0") {
        setInterval(() => { this.checkVersion(); }, Number(config.versionCheckFrequency));
      }
    },
    async checkVersion() {
      try {
        const response = await axios.create({
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).get("/version.json");
        this.checkedVersion = response.data.version || config.version;
        this.versionChanged = this.checkedVersion != config.version;
      } catch (error) {
        console.log("version check: could not poll version: ", error);
      }
    }
  }
};
