import Vue from 'vue'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { config } from '@/config';
import router from "@/router";

var getLocation = function(href) {
    var l = document.createElement("a");
    l.href = href;
    return l;
};

var tracingOrigins = [getLocation(config.backendUrl).hostname];

/* remove as login GFO has not changed ots CORS settings
 * TODO: add access checker and addr API
if (config.gfoApiUrl)
    tracingOrigins.push(getLocation(config.gfoApiUrl).hostname)
*/

if (config.sentryEnable) {
    Sentry.init({
        Vue: Vue,
        dsn: config.sentryDsn,
        release: config.version,
        environment: config.mandant,
        // debug: true,
        timeout: 2000,
        attachStacktrace: true,
        autoSessionTracking: true,
        trackComponents: true,
        tracesSampleRate: 0.01,
        initialScope: scope => {
            scope.setLevel(config.sentryDebugLevel);
            scope.setTag('mandant', config.mandant);
            scope.setExtra('config', config);
        },
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: tracingOrigins,
            }),
        ],
    });

    // Sentry.captureMessage('Hello, world!');
}

