import Vue from "vue";

function toCamelCase(key) {
  var c = key
    .replace("VUE_APP_", "")
    .split("_")
    .map(function (s) {
      return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
    })
    .join("");
  return c.charAt(0).toLowerCase() + c.substring(1);
}

function getCfg(file, mandant) {
  var cfg;
  if (mandant != null) cfg = require("./" + mandant + "/" + file);
  else cfg = require("./" + file);
  return cfg.default;
}

function fixCfg(cfg) {
  var fixedCfg = { ...cfg };
  for (let [key, value] of Object.entries(process.env)) {
    var cck = toCamelCase(key);
    fixedCfg[cck] = value;
  }
  return fixedCfg;
}

const mandant = process.env.VUE_APP_MANDANT || "iway";
const config = fixCfg({
  ...getCfg("config"),
  ...getCfg("config", mandant),
  mandant: mandant,
  baseUrl: process.env.BASE_URL,
  nodeEnv: process.env.NODE_ENV,
});

const vuetifyThemes = getCfg("vuetifyThemes", mandant);

const ConfigPlugin = {
  install(Vue, options) {
    Vue.prototype.$config = config;
  },
};

export default ConfigPlugin;
export { ConfigPlugin, config, vuetifyThemes };
