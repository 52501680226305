import Vue from "vue";
import Vuex from "vuex";
import * as Sentry from "@sentry/vue";

import { config } from "@/config";
import InternetOrder from "./modules/internetOrder";
import HardwareShop from "./modules/hardwareShop";
import GfoWebcomponent from "./modules/gfoWebcomponent.js";

function supports_html5_storage() {
  try {
    return "localStorage" in window && window["localStorage"] != null;
  } catch (e) {
    return false;
  }
}

function getDefaultLocal() {
  var locale = (
    window.navigator.userLanguage ||
    window.navigator.language ||
    config.i18nLocale
  )
    .split("-", 1)[0]
    .toLowerCase();
  return ["de", "en"].includes(locale) ? locale : config.i18nLocale;
}

if (!supports_html5_storage) {
  alert(
    "HTML5 localStorage seems not to be supported, please upgrade your browser!"
  );
}

Vue.use(Vuex);

const sessionDefaults = {
  id: null,
  apiRoles: [],
  isFallback: false,
  isClient: false,
  isPerson: false,
  isStaff: false,
  isStaffAdmin: false,
  isCcorporate: false,
  lastLogin: null,
  number: null,
  name: null,
  customerName: null,
  personName: null,
  personEmail: null,
  logoUploaded: false,
  language: "DE",
  billingType: null,
  billingPeriod: null,
  partnerType: null,
  permission: null,
  personId: null,
  tags: [],
  twoFactorConfirmed: false,
  twoFactorEnabled: false,
  twoFactorVerified: false,
  address: {
    company: "",
    first_name: "",
    last_name: "",
    salutation: "n",
    additional: "",
    street: "",
    street_number: "",
    zip_code: "",
    city: "",
    country: "CH",
    phone: "",
    mobile: "",
    fax: "",
    email: "",
  },
  deliveryAddresses: [],
  staffGroups: [],
  extraData: {},
};

function setToken(state, token) {
  state.token = token;
  if (localStorage) {
    if (token) localStorage.setItem("token", token);
    else localStorage.removeItem("token");
  }
  Sentry.configureScope((scope) => {
    scope.setExtra("token", token);
  });
}

function setAuthStatus(state, obj) {
  state.authState = obj != null ? obj.authState : null;
  state.authVerifier = obj != null ? obj.authVerifier : null;
  if (localStorage) {
    if (obj != null && obj.authState)
      localStorage.setItem("authState", obj.authState);
    else localStorage.removeItem("authState");
    if (obj != null && obj.authVerifier)
      localStorage.setItem("authVerifier", obj.authVerifier);
    else localStorage.removeItem("authVerifier");
  }
}

function setTwoFactor(state, response) {
  if (response)
    state.session = {
      ...state.session,
      twoFactorConfirmed: response.two_factor_confirmed,
      twoFactorEnabled: response.two_factor_enabled,
      twoFactorVerified: response.two_factor_verified,
    };
  else
    state.session = {
      ...state.session,
      twoFactorConfirmed: sessionDefaults.twoFactorConfirmed,
      twoFactorEnabled: sessionDefaults.twoFactorEnabled,
      twoFactorVerified: sessionDefaults.twoFactorVerified,
    };
  Sentry.configureScope((scope) => {
    scope.setExtra("twoFactor", {
      confirmed: state.session.twoFactorConfirmed,
      enabled: state.session.twoFactorEnabled,
      verified: state.session.twoFactorVerified,
    });
  });
}

function setLastLogin(state, ts) {
  if (ts !== null) {
    state.lastLogin = Date.parse(ts);
    if (localStorage) localStorage.setItem("lastLogin", ts);
  }
}

export default new Vuex.Store({
  modules: {
    inetOrder: InternetOrder,
    hardwareShop: HardwareShop,
    gfoWebcomponent: GfoWebcomponent,
  },
  state: {
    ready: false,
    token: localStorage ? localStorage.getItem("token") || null : null,
    authState: localStorage ? localStorage.getItem("authState") || null : null,
    authVerifier: localStorage
      ? localStorage.getItem("authVerifier") || null
      : null,
    locale: localStorage
      ? localStorage.getItem("locale") || getDefaultLocal()
      : getDefaultLocal(),
    session: sessionDefaults,
    lastLogin: Date.parse(
      localStorage
        ? localStorage.getItem("lastLogin") || new Date().toISOString()
        : new Date().toISOString()
    ),
    backendVersion: null,
    systemError: null,
    attributeTypes: null,
    messageScopes: [],
    messageScopesLoaded: false,
  },
  mutations: {
    setToken: setToken,
    setAuthStatus: setAuthStatus,
    setTwoFactor: setTwoFactor,
    setLastLogin: setLastLogin,
    login(state, response) {
      /* login simplification */
      setToken(state, response.token);
      setTwoFactor(state, response);
      setLastLogin(state, response.last_login);
      // Used for 2FA Title before login
      state.session.name = response.name;
      /* Bestell-Tokens von GFO-Webcomponent entfernen */
      localStorage.removeItem("gfo_order_reference");
      localStorage.removeItem("gfo_last_ordered_reference");
    },
    setSession(state, response) {
      state.session = {
        ...sessionDefaults,
        id: response.id,
        apiRoles: response.api_roles,
        isFallback: response.fallback,
        isClient: response.is_client,
        isPerson: response.is_person,
        isStaff: response.is_staff,
        isStaffAdmin: response.is_staff_admin,
        isCorporate: response.corporate,
        lastLogin: response.last_login,
        number: response.number,
        name: response.name,
        customerName: response.customer_name,
        personName: response.person_name,
        personEmail: response.person_email,
        partnerType: response.partner_type,
        permission: response.permission,
        personId: response.person_id,
        tags: [...response.tags],
        language: response.language,
        billingPeriod: response.billing_period,
        billingType: response.billing_type,
        logoUploaded: response.logo_uploaded,
        address: { ...response.address },
        deliveryAddresses: response.delivery_addresses,
        staffGroups: response.staff_groups,
        extraData: response.extra_data,
      };
      /** extend Sentry */
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: state.session.isPerson
            ? state.session.personId
            : state.session.id,
          name: state.session.name,
          username:
            state.session.number +
            (state.session.isPerson ? ":" + state.session.personEmail : ""),
          email: state.session.address.email,
        });
        scope.setExtra("session", {
          apiRoles: state.session.apiRoles,
          isStaff: state.session.isStaff,
          isStaffAdmin: state.session.isStaffAdmin,
          isClient: state.session.isClient,
          isPerson: state.session.isPerson,
          isFallback: state.session.isFallback,
        });
      });
      setTwoFactor(state, response);
      state.ready = true;
    },
    resetSession(state) {
      /* drop token, 2FA settings and reset session data */
      state.ready = false;
      setToken(state, null);
      setAuthStatus(state, null);
      setTwoFactor(state, null);
      state.session = { ...sessionDefaults };
    },
    setLocale(state, code) {
      state.locale = code || config.i18nLocale;
      if (localStorage) {
        if (code) localStorage.setItem("locale", code);
        else localStorage.removeItem("locale");
      }
    },
    setBackendVersion(state, version) {
      state.backendVersion = version;
      Sentry.configureScope((scope) => {
        scope.setExtra("backendVersion", version);
      });
    },
    setSystemError(state, error) {
      if (error) {
        state.systemError = {
          small: error.small || null,
          msg: error.msg || error,
          title: error.title || null,
          icon: error.icon || null,
        };
      } else {
        state.systemError = null;
      }
    },
    setAttributeTypes(state, types) {
      if (types != null) state.attributeTypes = { ...types };
      else state.attributeTypes = null;
    },
    setdeliveryAddresses(state, value) {
      state.session.deliveryAddresses = value;
    },
    setMessageScopes(state, scopes) {
      state.messageScopes = scopes;
    },
    setMessageScopesLoaded(state, value) {
      state.messageScopesLoaded = value;
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.token !== null;
    },
    isReady: (state) => {
      return state.token !== null && state.session.id != null && state.ready;
    },
    isStaff: (state) => {
      return state.session.isStaff;
    },
    isStaffAdmin: (state) => {
      return state.session.isStaffAdmin;
    },
    isAdmin: (state) => {
      return state.session.permission == "admin";
    },
    isPerson: (state) => {
      return state.session.isPerson;
    },
    isFallback: (state) => {
      return state.session.isFallback;
    },
    isReadOnly: (state) => {
      return state.session.tags.includes("read-only");
    },
    isCorporate: (state) => {
      return (
        Boolean(
          state.session.isCorporate ||
          state.session.address.company ||
          state.session.partnerType == "WHO"
        )  
      );
    },
    isWho: (state) => {
      return state.session.partnerType == "WHO" ? true : false;
    },
    isSalesPartner: (state) => {
      return state.session.partnerType == "PAR" ? true : false;
    },
    isEndCustomerr: (state) => {
      return state.session.partnerType == "END" ? true : false;
    },
    customerNumber: (state) => {
      return state.session.number;
    },
    customerId: (state) => {
      return state.session.id;
    },
    address: (state) => {
      return state.session.address;
    },
    apiRoles: (state) => {
      return state.session.apiRoles;
    },
    tags: (state) => {
      return state.session.tags;
    },
    username: (state) => {
      if (state.session.isPerson) {
        return state.session.personName || state.session.personEmail;
      } else {
        return state.session.customerName;
      }
    },
    name: (state, getters) => {
      return state.session.name || getters.username;
    },
    email: (state, getters) => {
      return state.session.isPerson
        ? state.session.personEmail
        : getters.address != null
          ? getters.address.email
          : null;
    },
    defaultDeliveryAddress: (state, getters) => {
      var deliveryAddresses = getters.deliveryAddresses;
      var i;
      for (i = 0; i < deliveryAddresses.length; i++) {
        if (deliveryAddresses[i].default === true) {
          // Check if the additional delivery address is at least somewhat usable
          if (
            !(
              state.session.deliveryAddresses[i].street &&
              state.session.deliveryAddresses[i].street.length > 0
            )
          ) {
            return state.session.address;
          }
          if (
            !(
              state.session.deliveryAddresses[i].city &&
              state.session.deliveryAddresses[i].city.length > 0
            )
          ) {
            return state.session.address;
          }
          if (
            !(
              state.session.deliveryAddresses[i].zip_code &&
              state.session.deliveryAddresses[i].zip_code.length > 0
            )
          ) {
            return state.session.address;
          }
          if (
            !(
              state.session.deliveryAddresses[i].email &&
              state.session.deliveryAddresses[i].email.length > 0
            )
          ) {
            return state.session.address;
          }
          return state.session.deliveryAddresses[i];
        }
      }
      return state.session.address;
    },
    deliveryEmail: (state, getters) => {
      if (getters.deliveryAddresses.length > 0)
        return getters.defaultDeliveryAddress.email;
      else if (getters.isPerson) return state.session.personEmail;
      else return getters.email;
    },
    deliveryAddresses: (state) => {
      // Make a copy of the object to not mett with origin object
      if (state.session.deliveryAddresses != null)
        return JSON.parse(JSON.stringify(state.session.deliveryAddresses));
      return [];
    },
    language: (state) => {
      return state.session.language;
    },
    country: (state, getters) => {
      return getters.address != null ? getters.address.country || "CH" : "CH";
    },
    twofa: (state, getters) => {
      return {
        confirmed: state.session.twoFactorConfirmed,
        enabled: state.session.twoFactorEnabled,
        verified: state.session.twoFactorVerified,
      };
    },
    staffGroups: (state) => {
      return state.session.staffGroups;
    },
    extraData: (state) => {
      return state.session.extraData;
    },
  },
  actions: {},
});
