<template>
  <v-bottom-sheet v-model="sheet" persistent>
    <v-sheet class="text-center" :height="height">
      <v-card class="mx-auto" flat>
        <v-card-actions class="justify-center">
          <v-btn class="my-6" color="error" @click="clearError()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
        <v-card-title v-if="title" class="justify-center red--text">
          <v-icon color="error" class="mr-4">{{ icon }} large</v-icon>
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-icon color="error" class="mr-4" v-if="!title" x-large>
            {{ icon }}
          </v-icon>
          {{ msg }}
        </v-card-text>
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  computed: {
    sheet: {
      get: function() {
        if (this.$store.state.systemError !== null) {
          console.log(this.$store.state.systemError);
          if (this.$store.state.systemError.small === true) {
            this.$snotify.error(
              this.$store.state.systemError.msg,
              this.$store.state.systemError.title,
              {
                timeout: 5000
              }
            );
            this.clearError();
            return false;
          } else {
            return this.$store.state.systemError !== null;
          }
        }
      },
      set: function(value) {}
    },
    height: function() {
      if (
        this.$store.state.systemError !== null &&
        this.$store.state.systemError.title
      ) {
        return "300px";
      } else {
        return "200px";
      }
    },
    msg: function() {
      if (
        this.$store.state.systemError !== null &&
        this.$store.state.systemError.msg
      ) {
        return this.$store.state.systemError.msg;
      }
    },
    title: function() {
      if (
        this.$store.state.systemError !== null &&
        this.$store.state.systemError.title
      ) {
        return this.$store.state.systemError.title;
      }
    },
    icon: function() {
      if (
        this.$store.state.systemError !== null &&
        this.$store.state.systemError.icon
      ) {
        return this.$store.state.systemError.icon;
      } else {
        return "mdi-alert-circle";
      }
    }
  },
  methods: {
    clearError() {
      this.$store.commit("setSystemError");
    }
  }
};
</script>

<i18n>
{
  "en": {
    "close": "Close"
  },
  "de": {
    "close": "Schliessen"
  }
}
</i18n>
