/**
 * Reload site.
 */

import router from "../../router";

export default {
  methods: {
    reloadSite: function (routeName) {
      if (routeName)
        this.$router.replace({
          name: routeName,
          params: { ...this.$route.params, reloadSite: true }, // router.afterEach will handle reloadSite
          query: { ...this.$route.query },
        });
      else location.reload();
    },
  },
};
